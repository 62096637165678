import { Application } from "@hotwired/stimulus";
import { Toggle } from "tailwindcss-stimulus-components";
import Slideshow from "../controllers/slideshow_controller";


const application = Application.start();

application.register("toggle", Toggle);
application.register("slideshow", Slideshow);

import "stylesheets/womeninvc.scss";
import "stylesheets/common.scss";

